/* OrganizationsList.css */

.table-container {
    max-width: 1300px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
}

.table-container th, .table-container td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table-container th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table-container td button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.table-container td button:hover {
    background-color: #d32f2f;
}

.table-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.table-container img {
    border-radius: 50%;
    object-fit: cover;
}