/* Dashboard Container */
.dashboard-wrapper {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

/* Title */
.dashboard-heading {
  color: #343a40;
  text-align: center;
  font-weight:bold !important;
  padding-bottom: 25px;
}
  
/* Top Stats Section */
.top-stats {
  justify-content: space-between;
  margin-bottom: 30px;
}

.stat-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  margin-left: 25px;
}

.stat-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.stat-icon {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 10px;
}

.stat-number {
  font-size: 1.8rem;
  color: #007bff;
}

.stat-text {
  font-size: 1rem;
  color: #6c757d;
}

/* Charts Section */
.chart-box {
  padding: 20px;  
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.chart-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #343a40;
}

@media (max-width: 768px) {
  .top-stats, .chart-box {
    flex-direction: column;
    align-items: center;
  }
}
/* Dashboard Container */
.dashboard-wrapper {
  padding: 20px;
  background-color: #f5f5f5;
  background-image: url(../Images/Nu_building2.png);
  background-color: #ffffff; /* Fallback color */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

/* Title */
.dashboard-heading {
  color: #343a40;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Top Stats Section */
.top-stats {
  justify-content: space-between;
  margin-bottom: 30px;
}

.stat-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.stat-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.stat-icon {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 10px;
}

.stat-number {
  font-size: 1.8rem;
  color: #007bff;
}

.stat-text {
  font-size: 1rem;
  color: #6c757d;
}

/* Charts Section */
.chart-box {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.chart-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #343a40;
}

/* Detail Boxes (Top Organizations & Recent Events) */
.detail-box {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.detail-heading {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #343a40;
  text-align: left; /* Align text to the left */
}

.detail-text {
  font-size: 1rem;
  color: #6c757d;
  margin: 5px 0; /* Space between items */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .top-stats, .chart-box, .detail-box {
    flex-direction: column;
    align-items: center;
  }
}
