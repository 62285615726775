.liquidation-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 90vh;
}
.inner-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 900px;
}
.liq-left{
    display: flex;
    border: 1px solid;
}
.liq-right{
    display: flex;
    padding: 10px;
    border:1px solid;
    height: 450px;
    border-radius: 30px;
}