body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Disable horizontal scrolling */
}

.superadmin-home {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
  background-image: url('../Images/Nu_building2.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  overflow-x: hidden; 
}

.header-superadmin {
  margin-top: 70px;
  text-align: center;
  padding: 1.5rem 1rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-superadmin {
  height: 180px;
  animation: fadeIn 2s ease-in-out;
}

.header-superadmin h2 {
  font-size: 2.5rem;
  color: #0175c8;
  animation: fadeIn 2s ease-in-out;
}

.menu-superadmin-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 1rem;
  flex-wrap: wrap;
}

.menu-superadmin-item {
  background-color: #f9f9f9;
  width: 245px;
  height: 175px;
  text-align: center;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-superadmin-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.menu-superadmin-item.active {
  background-color: #e0f3ff;
  border: 2px solid #0175c8;
}

.menu-superadmin-icon {
  font-size: 50px;
  color: #0175c8;
  margin-bottom: 0.5rem;
}

.quote-superadmin-container {
  margin-top: 10px;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 700px;
  animation: fadeInText 2s ease-in-out;
}

.quote-superadmin {
  font-size: 1.4rem;
  color: #333;
  font-style: italic;
  line-height: 1.8;
}

.parallax-sections-superadmin {
  width: 2200px;
  max-width: 100%;
}

footer-superadmin {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: white;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  margin-top: auto; /* Push the footer to the bottom of the page */
}

.footer-superadmin-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  width: 100%; /* Ensure full width for centering */
  text-align: center;
}

.footer-superadmin-section {
  padding: 10px;
}

.social-icons-container-superadmin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.social-superadmin-icon {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}

.contact-item-superadmin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.contact-item-superadmin span {
  margin-left: 10px;
  font-size: 15px;
}

@media (max-width: 768px) {
  .footer-superadmin-container {
    flex-direction: column;
  }

  .social-superadmin-icon {
    margin-bottom: 10px;
  }
} 

@media (max-width: 768px) {
  .header-superadmin h2 {
    font-size: 1.8rem;
  }

  .menu-superadmin-container {
    flex-direction: column;
  }

  .menu-superadmin-item {
    width: 100%;
    max-width: 300px;
  }

  .footer-superadmin-container {
    flex-direction: column;
  }

  .social-superadmin-icon {
    margin-bottom: 10px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}