/* Form Container */
.form-ubox-6 {
    display: flex;
    justify-content: center;
    padding: 40px;
    max-width: 100%;
    background-image: url(../Images/Nu_building2.png);
    background-color: #ffffff; /* Fallback color */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    overflow-x: hidden;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Form Inner Section */
  .inner-forms-6 {
    background-color: #fff; 
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 800px;
  }
  
  h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #2c3e50;
    text-align: center;
  }
  
  /* Form Group */
  .form-group-activity {
    margin-bottom: 30px;
    background-color: #1a3ab5;
    color: white;
    padding: 15px;
    border-radius: 8px;
    text-align: left;
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 30px;
  }
  
  /* Input Labels */
  .inner-forms label {
    font-size: 16px;
    font-weight: 500;
    color: #495057;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
  }
  
  /* Input Fields */
  input[type="text"],
  input[type="email"],
  input[type="date"],
  textarea,
  select {
    width: 95%;
    padding: 12px 15px;
    margin-bottom: 5px;
    border-radius: 8px;
    border: 2px solid rgb(214, 214, 214);
    background-color: #f9f9f9;
    font-size: 16px;
    color: #495057;
    transition: border-color 0.2s ease-in-out;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="date"]:focus,
  textarea:focus,
  select:focus {
    border-color: #80bdff;
    outline: none;
  }
  
  /* Radio & Checkbox */
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 8px;
  }
  
  .radio-group,
  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  /* Textarea */
  textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  /* Button Styles */
  .pdf-container button {
    width: 20%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .pdf-container button:hover {
    background-color: #0056b3;
  }
  
  /* Download Link */
  .download-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 45px;
    background-color: #007bff;
    color: white;
    border-radius: 8px;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .download-link:hover {
    background-color: #0056b3;
  }
  
  /* Section Title */
  .section-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
  }
  
  /* Form Row */
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  
  .form-row label {
    flex-basis: 30%;
    font-size: 16px;
  }
  
  .form-row input[type="text"],
  .form-row input[type="email"],
  .form-row input[type="date"],
  .form-row textarea {
    flex: 1;
  }
  
  .form-row input[type="radio"] {
    margin-top: 2px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
    }
  
    .form-row label {
      flex-basis: 100%;
    }
  
    .inner-forms {
      padding: 20px;
    }
  
    h1 {
      font-size: 22px;
    }
  
    input[type="text"],
    input[type="email"],
    input[type="date"],
    textarea,
    select {
      width: 100%;
    }
  }
  
  .event-dates{
    display: flex;
    
    justify-content: center;
    gap: 400px;
  }