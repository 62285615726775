/* General Styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f6f9;
    color: #333;
    margin: 0;
    padding: 0;
}

.form-container {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    margin: 50px auto;
    transition: all 0.3s ease-in-out;
}

.form-title {
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
    color: #333;
    font-weight: 700;
}

/* Success and Error Messages */
.success-message {
    color: #28a745;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.error-message {
    color: #dc3545;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

/* Form Body */
.form-body {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

/* Profile Image Section */
.profile-image-section {
    flex: 1 1 250px;
    text-align: center;
}

.profile-image {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.placeholder-logo {
    font-size: 14px;
    color: #777;
}

.upload-btn {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-btn input {
    display: none;
}

.upload-btn:hover {
    background-color: #0056b3;
}

/* Form Fields */
.form-fields {
    align-items: center;
    justify-content: center;
}

.form-group {
    margin-bottom: 20px;
    align-items: flex-start;
    width: 370px;
    background-color: white;
}

.form-group label {
    font-weight: 600;
    display: block;
    margin-bottom: 2px;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
    border-color: #007bff;
    outline: none;
}

.input-error {
    border-color: #dc3545 !important;
}

.error-text {
    color: #dc3545;
    font-size: 12px;

}

/* Required field indicator */
.important {
    color: #dc3545;
}

/* Submit Button */
.btn {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    transition: background-color 0.3s ease;
    width: 400px;
}

.btn:hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .form-body {
        flex-direction: column;
    }

    .profile-image-section,
    .form-fields {
        width: 100%;
    }

    .upload-btn {
        width: 100%;
        text-align: center;
    }
}
