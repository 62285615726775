/* General Styles */
.wrap {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  background-image: url('../Images/Nu_building2.png');
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Calendar Container */
.calendar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  padding: 0 20px;
  height: 100%;
}

/* Event Details - Left Side */
.event-details {
  width: 30%;
  height: 100%;
  background-color: #ffffff;
  color: #333333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  margin-left: 20px;
}

.event-details h2 {
  font-size: 1.4rem;
  color: #1a3ab5; /* Color for date text */
  margin-bottom: 16px;
}

.event-details ul {
  list-style: none;
  padding: 0;
}

.event-details ul li {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f1f3f5;
  border-radius: 6px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.event-details ul li:hover {
  background-color: #e2e6ea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.event-title {
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  color: #1a3ab5;
}

.event-time {
  font-size: 0.9rem;
  color: #555555;
}

.no-events {
  font-style: italic;
  color: #777777;
}

/* Calendar Wrapper - Right Side */
.calendar-wrapper {
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  width: 65%;
  min-width: 300px;
  margin-bottom: 20px;
}

.calendar-header {
  background-color: #1a3ab5;
  padding: 18px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
}

.month-title {
  font-size: 1.6rem;
  color: #ffffff;
  font-weight: bold;
}

.calendar-content {
  padding: 20px;
}

.rbc-month-view {
  width: 100%;
  height: 100%;
}

.rbc-month-row {
  display: flex;
}

.rbc-month-row:nth-child(odd) {
  background-color: #f9f9f9;
}

.rbc-month-row:nth-child(even) {
  background-color: #ffffff;
}

.rbc-date-cell {
  padding: 12px;
  border: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;
  text-align: center;
}

.rbc-date-cell:hover {
  background-color: #e1ebfd;
  cursor: pointer;
}

.rbc-date-cell.rbc-today {
  background-color: #c1d2ff; /* Lighter shade for today */
}

.rbc-event {
  font-size: 0.9rem;
  padding: 6px 10px;
  background-color: #ff9900;
  color: black; 
  border-radius: 5px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.rbc-event:hover {
  background-color: #f0ac2f;
}

/* Custom Toolbar */
.custom-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.toolbar-label {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333333;
}

.toolbar-buttons {
  display: flex;
  gap: 15px;
}

.toolbar-button {
  background-color: #1a3ab5;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toolbar-button:hover {
  background-color: #1634a1;
}

/* Image Slider */
.img-slide {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
}

.slide {
  position: relative;
}

.slider-image {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease;
}

.slider-image:hover {
  transform: scale(1.02);
}

/* Edit Event Form */
.edit-event-form {
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 20px 50px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.edit-event-form h3 {
  margin-bottom: 15px;
  color: #1a3ab5;
}

.edit-event-form input[type="text"],
.edit-event-form input[type="datetime-local"] {
  width: 100%;
  padding: 10px 2px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.edit-event-form input[type="text"]:focus,
.edit-event-form input[type="datetime-local"]:focus {
  border-color: #1a3ab5;
}

.edit-event-form button {
  width: 100%;
  padding: 12px 0;
  background-color: #1a3ab5;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-event-form button:hover {
  background-color: #1634a1;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .calendar-container {
    flex-direction: column;
    align-items: center;
  }

  .event-details,
  .calendar-wrapper {
    width: 90%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .event-details {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .slider-image {
    height: 300px;
  }

  .month-title {
    font-size: 1.2rem;
  }

  .toolbar-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .toolbar-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .slider-image {
    height: 200px;
  }

  .month-title {
    font-size: 1rem;
  }

  .toolbar-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .event-details {
    padding: 15px;
  }

  .edit-event-form {
    padding: 15px 20px;
  }
}
