/* src/AdminHome.css */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Disable horizontal scrolling */
}

.admin-home {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
  background-image: url('../Images/Nu_building2.png');
  background-size: cover;
  background-position: center;
  width: 100%; /* Ensure admin-home takes full width */
  overflow-x: hidden; /* Disable horizontal scrolling */
}

.header-admin {
  margin-top: 70px;
  text-align: center;
  padding: 1.5rem 1rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 180px;
  animation: fadeIn 2s ease-in-out;
}

.header-admin h2 {
  font-size: 2.5rem;
  color: #0175c8;
  animation: fadeIn 2s ease-in-out;
}

.menu-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 1rem;
  flex-wrap: wrap;
}

.menu-item {
  background-color: #f9f9f9;
  width: 200px;
  height: 150px;
  text-align: center;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    
}

.menu-item.active {
  background-color: #e0f3ff;
  border: 2px solid #0175c8;
}

.menu-icon {
  font-size: 50px;
  color: #0175c8;
  margin-bottom: 0.5rem;
}

.quote-container {
  margin-top: 10px;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 700px;
  animation: fadeInText 2s ease-in-out;
}

.quote {
  font-size: 1.4rem;
  color: #333;
  font-style: italic;
  line-height: 1.8;
}

.parallax-sections {
  width: 2200px;
  max-width: 100%;
}

.footer2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: white;
  width: 100%; /* Make footer full width */
  padding: 20px 0;
  box-sizing: border-box; /* Include padding in width */
  overflow: hidden; /* Prevent any overflow */
}

.footer2-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.footer2-section {
  flex: 1;
  padding: 10px;
  text-align: center;
}

.social-icons-container {
  display: flex;
  justify-content: center;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.contact-item span {
  margin-left: 10px;
  font-size: 15px;
}


@media (max-width: 768px) {
  .menu-container {
    flex-direction: column;
  }

  .footer2-container {
    flex-direction: column;
  }
}
