.admin-form-view {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

h1, h2 {
  text-align: center;
  color: #333;
}

.form-list {
  margin-bottom: 20px;
}

ul {
  padding: 0;
}

.admin-button {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.admin-button:hover {
  background-color: #45a049;
}

.form-details {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.admin-form-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.admin-label {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.inputtypeadmin {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 14px;
}

.inputtypeadmin:focus {
  border-color: #4CAF50;
  outline: none;
  background-color: white;
}

@media (max-width: 768px) {
  form {
    grid-template-columns: 1fr;
  }

  .admin-button {
    font-size: 14px;
    padding: 10px;
  }
}
