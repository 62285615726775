/* Overall Layout */
.fs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-image: url(../Images/Nu_building2.png);
  background-color: #ffffff; /* Fallback color */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  overflow-x: hidden; 
  height:700px;
}

.fs-title {
  color: #003366;
  font-size: 2.2em;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Steps Styling */
.steps {
  display: flex;
  width: 1000px !important;
  gap: 30px;
  justify-content: center;
  flex-direction: row;
}

.step {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 40px 25px; /* Adjusted padding for better spacing */
  width: 280px;
  text-align: center;
  transition: transform 0.3s;
  position: relative; /* Allows for the absolute positioning of the icon */
}

/* .step:hover {
  transform: translateY(-10px);
} */

/* Adjusting Icon for better visibility */
.step-icon {
  font-size: 150px !important; /* Increased size */
  color: #b0b0b0; /* Slightly darker gray for better contrast */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centering */
  z-index: 0; /* Behind the text */
  opacity: 0.3; /* Slightly less transparent */
}

.step h2, .step p {
  position: relative;
  z-index: 1; /* Text above the icon */
}

.step h2 {
  font-size: 1.4em;
  color: #003366;
  margin-bottom: 15px;
}

.step p {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
}

/* Bottom Links */
.bottom-links {
  display: flex;
  gap: 60px;
  margin-top: 40px;
}

.link {
  background-color: #1a3ab5;;
  color: white;
  border-radius: 8px;
  padding: 15px 25px;
  text-align: center;
  width: 200px;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;
  /* transition: background-color 0.3s ease; */

}

.forms-link:hover {
  background-color: #003366;
  transform: translateY(-5px);
}

.proposal-link:hover {
  background-color: #003366;
  transform: translateY(-5px);
}


/* Responsive Design */
@media (max-width: 768px) {
  .steps {
    flex-direction: column;
  }
  
  .bottom-links {
    flex-direction: column;
    gap: 20px;
  }
}
