/* Container Styles */
.f-box {
    display: flex;
    flex-direction: column;
    padding: 30px;
    min-height: 84vh;
    background-image:url('../Images/Nu_building2.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    overflow-x: hidden;

}

.f-title {
    text-align: center;
    color: #0055a2;
    margin-bottom: 40px;
    font-size: 2em;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

/* Papers Container */
.papers-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

/* Individual Form Box */
.fi-box-1 {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    width: 300px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.fi-box-1:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}

.icon {
    font-size: 2.5em;
    color: #0055a2;
    margin-bottom: 15px;
}

.form {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
}

.fi-box-1 p {
    font-size: 0.95em;
    color: #666;
    font-family: 'Roboto', sans-serif;
}

/* Responsive Design */
@media (max-width: 768px) {
    .fi-box-1 {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .fi-box-1 {
        width: 100%;
    }
}
