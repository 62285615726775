/* General Styles */
.m-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px); /* Adjust height to avoid unnecessary gaps */
  font-family: "Poppins", sans-serif;
  background: linear-gradient(135deg, #f0f4ff, #d9e6ff);
  padding: 0; /* Remove unnecessary padding */
  margin-top: -20px; /* Adjust margin if needed */
}



/* Profile Card */
.profile-container {
  display: flex;
  max-width: 700px;
  width: 90%;
  padding: 25px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.8s ease-in-out;
  transition: transform 0.3s ease-in-out;
  margin-top: -30px; /* Adjust this value to bring it closer to the navbar */
}


.profile-container:hover {
  transform: scale(1.02);
}

/* Profile Left Section */
.profile-left {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.profile-pic {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 0 auto 10px;
  border: 4px solid #0046ad;
  transition: all 0.3s ease-in-out;
}

.profile-pic:hover {
  transform: scale(1.1);
}

.profile-left h2 {
  margin: 10px 0;
  color: #0046ad;
  font-size: 1.6rem;
  font-weight: bold;
}

.profile-left p {
  color: #666;
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 5px;
}

/* Profile Right Section */
.profile-right {
  flex: 2;
  padding: 20px;
}

.profile-right h2 {
  color: #0046ad;
  margin-bottom: 15px;
  font-size: 1.6rem;
  text-align: center;
}

/* Form Styling */
.profile-right form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.profile-right label {
  font-weight: bold;
  color: #333;
  display: block;
}

.profile-right input {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.profile-right input:focus {
  border-color: #0046ad;
  box-shadow: 0 0 8px rgba(0, 70, 173, 0.2);
  outline: none;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 95%;
  }

  .profile-left, .profile-right {
    width: 100%;
    text-align: center;
  }

  .profile-right form {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
}
