.prog-box {
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 78%;
  margin: 40px auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

h3 {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
}

.progress-tracker {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.upper-row {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60%;
  position: relative;
}

.progress-bar-container::before {
  content: '';
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 12px;
  width: 2px;
  background-color: #ddd;
  z-index: 0;
}

.step-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.progress-step {
  margin-right: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e0e0e0;
  position: relative;
  z-index: 1;
}

.step-label {
  font-size: 16px;
  color: #555;
  position: relative;
  margin: 15px 0;
}

.timestamp {
  font-size: 12px;
  color: #999;
  margin-left: 8px;
}

/* Edit Tracker Section */
.edit-tracker {
  display: flex;
  flex-direction: column;
  background-color: #0047ab;
  padding: 20px;
  border-radius: 10px;
  width: 35%;
}

.edit-tracker-title {
  text-align: center;
  color: yellow;
  font-size: 20px;
  margin-bottom: 20px;
}

.edit-tracker-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-container {
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
}

.checkbox-container input {
  margin-right: 10px;
  transform: scale(1.5); /* Resize the checkbox */
}

/* Save Button */
.save-button {
  margin-top: 20px;
  background-color: yellow;
  color: blue;
  font-weight: bold;
  border-radius: 8px;
  width: 100px;
  padding: 8px 0;
}

/* Remarks Section */
.remarks-section {
  display: flex;
  align-items: flex-start;
  width: 39%;
  flex-direction: column;
  margin-top: 20px;
}

.remarks-section textarea {
  width: 100%;
  max-width: 400px;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
  margin-bottom: 10px;
}

.send-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 10px;
  justify-content: flex-end;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #45a049;
}

.action-buttons {
  gap: 20px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.action-button {
  border-radius: 50px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.action-button:hover {
  background-color: #0056b3;
}

.action-button:last-child {
  margin-right: 0;
}
